export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/conflict": [5],
		"/hi/[reservationCode]": [~6],
		"/moderate": [~7,[2]],
		"/moderate/custom-payments": [~8,[2]],
		"/moderate/custom-payments/create": [~9,[2]],
		"/moderate/custom-payments/view/[customPaymentId]": [~10,[2]],
		"/moderate/hotels": [~11,[2]],
		"/moderate/id-docs": [~12,[2]],
		"/moderate/login": [~13,[2]],
		"/pay/[customPaymentId]": [~14],
		"/[...catchall]": [~4]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';